* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Montserrat';
}

img {
    object-fit: cover;
    width: 100%;
}

@font-face {
    font-family: "Montserrat";
    src: url('./font/Montserrat-Regular.ttf');
    font-weight: normal;
    font-style: normal;
}

/****************************************************/
/*************************GRID***********************/
/****************************************************/

.row {
    width: 100%;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
}

.row-content-second {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 100%;
}

.img-background {
    background-image: url('../img/Bg_Header@2x.png') !important;
    background-repeat: repeat-x;
}

.img-background-content-primary {
    background-repeat: repeat-x;
    font-size: 32px;
    text-align: justify;
}

.img-background-head {
    background: url('../img/Bg_Header.png');
    background-repeat: repeat-x;
    margin-top: 4em !important;
}

.article {
    margin-left: 3em;
    width: 100%;
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.text-primary-span2 {
    font-weight: 990;
}

.text-primary-span3 {
    font-weight: bold;
    color: #7CD10F;
}

.text-primary-span4 {
    padding-left: .5em;
    text-align: center;
    font-weight: bold;
    color: #17a2b8;
}

nav div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

.render-logo {
    width: 60%;
}

.container-img {
    width: 100%;
    display: flex;
    justify-content: space-evenly !important;
    align-items: center;
}

.img-response {
    width: 60%;
}

.img-responses {
    width: 40%;
    margin-right: 2em;
}

.space-col {
    flex-shrink: 3 !important;
}

.space-button {
    display: flex;
    justify-content: space-evenly;
}

.jumbotrom {
    margin: 1em auto;
    height: 300px;
    background-image: none;
}

.loop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('../img/img_woloxer@2x.png');
    background-position: center;
    width: 100% !important;
    background-repeat: no-repeat;
}

.container-twitter {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: white;
}

.container-twitter>.twitter {
    width: 7%;
}

.loop>p.article {
    display: flex;
    width: 100%;
    justify-content: center;
    height: 100px !important;
    align-items: center;
    margin: 0 !important;
}

.f-shrink {
    width: 100%;
    margin: 0 !important;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
    flex-shrink: 2 !important;
    background-color: #E8F3F7;
}

.f-shrink>.text-content-primary {
    text-align: center;
}

.text-content-primary-requerimientos {
    text-align: center;
    font-weight: bold;
}

.container-galery {
    display: flex;
    justify-content: space-evenly !important;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
}

.row>div {
    display: flex;
    justify-content: center;
    align-items: center;
}

.row>div>p {
    font-size: 28px;
    margin: 1em auto;
}

.carita-picara {
    margin-left: .4em;
    color: #17a2b8;
    font-weight: bold;
}

.container-galery>div {
    text-align: center;
}

.lapto {
    width: 90px !important;
}

.galery {
    transition: 1s;
    -moz-transition: 1s;
    -webkit-transition: 1s;
    -o-transition: 1s;
}

.galery:hover {
    transform: rotate(-25deg);
    -moz-transform: rotate(-25deg);
    -webkit-transform: rotate(-25deg);
    -o-transform: rotate(-25deg);
}

.galery {
    width: 60px;
    height: auto !important;
}

.legend {
    font-size: 16px;
    margin-top: 1em;
}

.render-start {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    list-style: none;
}

.list {
    width: 80%;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2em;
    text-align: justify;
}

.list-icono1::before {
    content: url('../img/Ic_Bullet_1.png');
    display: inline-block;
    margin-left: -2.5em;
    width: 2.5em;
}

.list-icono2::before {
    content: url('../img/Ic_Bullet_2.png');
    display: inline-block;
    margin-left: -2.5em;
    width: 2.5em;
}

.list-icono3::before {
    content: url('../img/Ic_Bullet_3.png');
    display: inline-block;
    margin-left: -2.5em;
    width: 2.5em;
}

.footer {
    width: 100%;
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    background-image: url('../img/Bg_Footer.png');
    background-repeat: repeat-x;
    background-size: 50%;
}

.conocer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: center;
}

.conocer>a {
    padding-top: .6em !important;
    padding-bottom: .6em !important;
    font-weight: bold !important;
    font-size: 14px !important;
    width: 300px;
    border-radius: 2rem;
    margin-top: 1.2em;
}

.footer>.conocer>p {
    width: 100%;
    margin: 0px !important;
}

.footer>.conocer>p:nth-child(1) {
    font-weight: bold;
    font-size: 32px;
    text-align: center;
}

.footer>.conocer>p:nth-child(2) {
    font-size: 19px;
    text-align: center;
}

.pie {
    width: auto;
    height: 150px;
    display: flex;
    justify-content: center !important;
    align-items: flex-end !important;
}

.img-pie {
    width: 30%;
}

.style-img-login {
    margin-left: 1em;
    width: 100%;
    height: auto;
}

.style-img-login img {
    width: 30%;
}

hr {
    margin: 3em 2.5em 0 2.5em;
    border: 8px solid #f6f6f6;
    border-radius: 5px;
    -webkit-box-shadow: 0px 6px 5px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 6px 5px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 6px 5px 1px rgba(0, 0, 0, 0.75);
}

.flex-start-y-spacex>ul {
    margin-right: .5em;
}

.flex-start-y-spacex>ul>h4 {
    text-align: center;
}

.flex-start-y-spacex>ul>li>span {
    margin: .5em auto;
    text-align: justify;
}

.list-img {
    width: 100px;
}

.flex-start-y-spacex ul li a {
    text-align: center;
}

.flex-start-y-spacex ul li img {
    margin-top: .1em;
    width: 30% !important;
}

.table-column {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
}

.filtros {
    margin-top: 2em;
    width: 100%;
    margin-left: .5em;
    flex-shrink: 4;
}

.filtros input {
    margin-left: .2em;
}

.filtros h2,
.filtros h3 {
    text-align: center;
    margin-bottom: .5em;
}

.flex-start-y-spacex {
    height: auto;
    margin-top: 2em;
    align-items: flex-start !important;
    justify-content: space-evenly !important;
}

cite {
    width: 100%;
    display: block !important;
    text-align: center;
}

@supports(object-fit: cover) {
    .loop {
        height: 300px;
        object-fit: cover;
        object-position: center center;
    }
}

/***************************************************/
/************************FORM***********************/
/***************************************************/

.reset-form {
    margin-bottom: 1rem;
}

.check {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
}

.form-input-reset {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.label {
    font-size: 20px;
    margin-bottom: .5em;
}

.form-check>label {
    font-size: 16px;
}

.btn-style {
    color: #17a2b8 !important;
    border: 1px solid #17a2b8;
    border-radius: .5rem;
    padding-left: 4em;
    padding-right: 4em;
    font-size: .8rem;
    cursor: pointer;
}

.width-full {
    width: 100%;
}

.container-form {
    width: 100%;
    height: 500px;
}

.card-form {
    width: 50%;
    position: relative;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 1em;
    -webkit-box-shadow: 0px 11px 14px 3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 11px 14px 3px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 11px 14px 3px rgba(0, 0, 0, 0.75);
}

.label-password {
    font-size: 12px;
}

.error-input {
    border-color: red;
}

.success-input {
    border-color: #7CD10F;
}

/***************************************************/
/***********************BUTTON**********************/
/***************************************************/

.btn {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    font-weight: bold;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.loop .btn-complejo {
    color: white !important;
}

.btn-simple {
    color: black;
    font-size: 1rem;
}

.space-button .btn-simple {
    position: relative;
    display: block;
    padding: 5px;
}

.space-button .btn-simple::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, rgba(179, 220, 237, 1) 0%, rgba(41, 184, 229, 1) 50%, rgba(188, 224, 238, 1) 100%);
    z-index: 1;
    transform: scaleX(0);
    transform-origin: left;
    transition: transform 0.5s ease-in-out;
}

.space-button .btn-simple:hover::before {
    transform: scaleX(1);
}

.space-button[data-animation="center"] .btn-simple::before {
    transform-origin: center;
}

.btn-complejo {
    color: #17a2b8;
    border: 1px solid #17a2b8;
    border-radius: 1rem;
    padding-left: 4em;
    padding-right: 4em;
    font-size: .8rem;
    cursor: pointer;
}

.btn-complejo:focus {
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-complejo:hover {
    color: #fff;
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.loop .btn-complejo:hover {
    color: #17a2b8 !important;
    background-color: #FFF;
    border-color: #FFF;
}

.conocer>a:hover {
    color: #17a2b8 !important;
    background-color: #FFF;
    border-color: #FFF;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.d-none {
    display: none;
}

.conocer>a {
    background-color: #17a2b8;
    color: white;
}

.btn-style:hover {
    color: #FFF !important;
    background-color: #17a2b8;
    border-color: #17a2b8;
    box-shadow: 0 0 0 0.2rem rgba(23, 162, 184, 0.5);
}

.btn-empty {
    display: inline;
    border: 0;
    padding: 0;
    margin: 0;
    background: none;
    color: #000088;
    font-family: arial, sans-serif;
    font-size: 1em;
    line-height: 1em;
    position: absolute;
    top: 1em;
    right: 7em;
    font-family: 14px;
}

.btn-empty:hover {
    text-decoration: none;
    color: #0000cc;
    cursor: pointer;
}

/***************************************************/
/*********************MEDIA*************************/
/***************************************************/

@media screen and (min-width: 1200px){
    .img-response {
        width: 50%;
    }

    .img-responses{
        width: 30%;
    }
}

@media screen and (max-width:1057px) {
    .render-start {
        justify-content: center;
        align-items: center;
    }

    .row-content-second {
        flex-direction: column;
    }

    .img-response, .img-responses {
        width: 30%;
    }
}

@media screen and (max-width:844px) {
    .flex-start-y-spacex ul {
        width: 100%;
        border: 1px solid;
        margin: 0 !important;
    }

    .flex-start-y-spacex ul h4 {
        display: none;
    }

    .table-column {
        align-content: stretch !important;
        justify-content: baseline !important;
        flex-direction: row;
        text-align: justify;
    }

    .table-column::before {
        height: 60px !important;
        width: 220px !important;
        padding: auto 1em;
        margin-right: 2em;
        font-weight: bold;
    }

    .label-1::before {
        content: 'Tecnologías';
    }

    .label-2::before {
        content: 'Lenguajes';
    }

    .label-3::before {
        content: 'Tipos';
        margin-right: 4.5em;
    }

    .label-4::before {
        content: 'Licencias';
        margin-right: 3em;
    }

    .label-5::before {
        content: 'Autores';
        margin-right: 3em;
    }

    .label-6::before {
        content: 'Año';
        margin-right: 5.5em;
    }

    .label-7::before {
        content: 'Imagen';
    }

    .table-column span {
        width: 100%;
        padding-right: .5em;
    }
}

@media screen and (max-width:750px) {

    nav div,
    .row-content-second {
        flex-direction: column;
    }

    .img-responses {
        width: 60% !important;
    }

    .m-tr {
        margin-top: 2em;
    }

    .img-responses,
    .article {
        margin: 0;
    }

    .article {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .space-col {
        margin-top: 1rem;
    }

    .render-start {
        justify-content: center;
        align-items: center;
    }

    .conocer>p:nth-child(1),
    .conocer>p:nth-child(2) {
        font-size: 24px;
    }

    .card-form {
        width: 80%;
    }

    .style-img-login {
        width: 100%;
    }
    
    .filtros{
        justify-content: flex-start !important;
        align-items: flex-start;
    }
}

@media screen and (max-width:570px) {

    .img-response {
        width: 60% !important;
    }
}

@media screen and (max-width:320px) {

    .img-background-content-primary {
        font-size: 24px;
    }

    .conocer>p:nth-child(1),
    .conocer>p:nth-child(2) {
        margin-bottom: 1em !important;
    }

    .img-responses {
        width: 80%;
    }
}
